import React, {Component} from 'react';
import GeneralHeader from "../../components/common/GeneralHeader";
import Breadcrumb from "../../components/common/Breadcrumb";
import PlaceGrid from "../../components/places/PlaceGrid";
import Button from "../../components/common/Button";
import {FiRefreshCw} from "react-icons/fi";
import Footer from "../../components/common/footer/Footer";
import ScrollTopBtn from "../../components/common/ScrollTopBtn";
import GenericHeader from "../../components/common/GenericHeader";
import breadcrumbimg from '../../assets/images/locations/bg_locations_1.png';
import axios from 'axios';
import ClipLoader from "react-spinners/ClipLoader";


class ListingGrid extends Component {
    state = {
        search: '',
        selectedPark: 1,
        selectedCategory: 1,
        bdimg: breadcrumbimg,
        results: []
    }
    constructor(props) {
        super(props)
        const { search, selectedPark, selectedCategory } = props.match.params;
        // const newbgimg = require.context('../../assets/images/bg_locations_1.png', true);
        this.state = {
            search: search,
            selectedPark: selectedPark,
            selectedCategory: selectedCategory,
            results: [],
            isLoaded: false
        }
        this.getImgUrl();
        this.getResults();
        // console.log(backgroundimg);
    }

    getImgUrl = () => {
        axios.get('https://lumiere-server-2q7e4.ondigitalocean.app/api/parks/' + this.state.selectedPark)
            .then(res => {
                if (res.data) {
                    this.setState({
                        bdimg: res.data.imageUrl
                    })
                }
            })
            .catch(err => console.log(err))
    }

    getResults = () => { 
        let url = 'https://lumiere-server-2q7e4.ondigitalocean.app/api/items/' + this.state.search + '/' + this.state.selectedPark + '/' + this.state.selectedCategory;
        axios.get(url)
            .then(res => {
                if (res.data) {
                    this.setState({
                        results: res.data,
                        isLoaded: true
                    })
                }
            })
            .catch(err => console.log(err))
    }
    
    render() {
        const { isLoaded, results } = this.state;
        
        return (
            <main className="listing-grid">
                {/* Header */}
                <GeneralHeader />

                {/* Breadcrumb */}
                <Breadcrumb CurrentPgTitle={this.state.search} MenuPgTitle="Listings" img={ this.state.bdimg}/>

                {/* Place Grid */}
                <section className="card-area padding-top-40px padding-bottom-100px">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                {isLoaded && results.length > 0 ? <GenericHeader /> : ''}
                            </div>
                        </div>
                        
                        <div className="row">
                            {
                                isLoaded && results.length>0 ? <PlaceGrid results={results} /> :
                                    <span>
                                        <ClipLoader></ClipLoader>
                                    </span>
                                    
                            }
                            
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="button-shared mt-4 text-center">
                                    {isLoaded && results.length > 0 ? 
                                        <Button text="Load More" url="#">
                                            <span className="la">
                                                <FiRefreshCw />
                                            </span>
                                        </Button> : ''
                                    }
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* Newsletter */}
                {/* <NewsLetter /> */}

                {/* Footer */}
                <Footer />

                <ScrollTopBtn />
            </main>
        );
    }
}

export default ListingGrid;