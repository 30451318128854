import React, {Component} from 'react'
import BannerOneHeroHeading from './BannerOneHeroHeading'
import BannerOneSearchInput from './BannerOneSearchInput'
// import BannerOneCategories from './BannerOneCategories'
import axios from 'axios'

export default class BannerOne extends Component {
    componentDidMount() {
        this.getParks();
    }
    getParks = () => {
        axios.get('https://lumiere-server-2q7e4.ondigitalocean.app/api/parks')
            .then(res => {
                if (res.data) {
                    this.setState({
                        parks: res.data
                    })
                }
            })
            .catch(err => console.log(err))
    }
    render() {
        return (
            <>
                <section className="hero-wrapper">
                    <div className="hero-overlay"></div>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">

                                {/* Banner One Hero Heading */}
                                <BannerOneHeroHeading />

                                {/* Banner One Search Input */}
                                <BannerOneSearchInput />

                                {/* Banner One Categories */}
                                {/* <BannerOneCategories />  */}

                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}
