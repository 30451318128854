import React, {Component} from 'react';
import { IoMdStar, IoMdStarHalf} from 'react-icons/io'
import { AiOutlineEye } from 'react-icons/ai'
import {  RiPlantLine } from 'react-icons/ri'
import {  GiPalette } from 'react-icons/gi'
import { SiGroupon } from 'react-icons/si';
import {  FiHeart } from 'react-icons/fi'
import { FaRegCalendarCheck } from 'react-icons/fa'
import Slider from "react-slick";
import img1 from '../../assets/images/farts1.jpg';
import img2 from '../../assets/images/farts2.jpg';
import img3 from '../../assets/images/farts3.jpg';

// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

class PlaceOne extends Component {
    states = {
        items: [
            {
                badge: 'Returning Item',
                title: 'Pan-Seared Scallop with Chorizo, Roasted Red Pepper Coulis and a Parmesan Crisp',
                // titleIcon: <IoIosCheckmarkCircle />,
                titleUrl: '#',
                stitle: "Epcot, The Artist's Table",
                image: img1,
                cardType: 'Festival of the Arts',
                cardTypeIcon: <GiPalette />,
                // author: authorimg,
                // authorUrl: '#',
                glutenFree: true,
                plantBased: false,
                // websiteUrl: 'https://example.com',
                date: 'Last Reviewed 1 day ago',
                view: '204',
                ratings: [
                    <IoMdStar />,
                    <IoMdStar />,
                    <IoMdStar />,
                    <IoMdStarHalf />,
                    <IoMdStar className="last-star" />,
                ],
                ratingNum: '4.7'
            },
            {
                badge: 'New',
                title: 'Sushi Donut',
                // titleIcon: <IoIosCheckmarkCircle />,
                titleUrl: '#',
                stitle: 'Epcot, Goshiki',
                image: img2,
                cardType: 'Festival of the Arts',
                cardTypeIcon: <GiPalette />,
                // author: authorimg,
                // authorUrl: '#',
                glutenFree: false,
                plantBased: false,
                date: 'Last Reviewed 1 month ago',
                view: '204',
                ratings: [
                    <IoMdStar />,
                    <IoMdStar />,
                    <IoMdStar />,
                    <IoMdStarHalf />,
                    <IoMdStar className="last-star" />,
                ],
                ratingNum: '4.5'
            },
            {
                badge: 'Returning Item',
                title: 'vanilla, rose water and pistachio panna cotta',
                titleIcon: '',
                titleUrl: '#',
                stitle: 'Epcot, The Masterpiece Kitchen',
                image: img3,
                cardType: 'Festival of the Arts',
                cardTypeIcon: <GiPalette />,
                // author: authorimg,
                // authorUrl: '#',
                glutenFree: false,
                plantBased: false,
                date: 'Last Reviewed 2 weeks ago',
                view: '248',
                ratings: [
                    <IoMdStar />,
                    <IoMdStar />,
                    <IoMdStar />,
                    <IoMdStarHalf />,
                    <IoMdStar className="last-star" />,
                ],
                ratingNum: '4.6'
            },
        ]
    }
    responsive = [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                infinite: true,
                dots: true
            }
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                infinite: true,
                dots: true
            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                initialSlide: 2
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
    render() {
        return (
            <div className="row mt-5">
                <div className="col-lg-12">
                    <Slider className="places-carousel" dots={true} infinite={true} slidesToScroll={2} arrows={false} slidesToShow={3} centerMode={false} centerPadding="50px" autoplay={true} responsive={this.responsive}>
                        {this.states.items.map((item, index) => {
                            return (
                                <div className="card-item" key={index}>
                                    <a href={item.titleUrl} className="card-image-wrap">
                                        <div className="card-image">
                                            <img src={item.image} className="card__img" alt="Place" />
                                            <span className={item.titleIcon ? 'badge': 'badge badge-closed' }>{item.badge}</span>
                                            <span className="badge-toggle" data-toggle="tooltip" data-placement="bottom" title="22 Likes">
                                                <FiHeart />
                                            </span>
                                        </div>
                                    </a>
                                    <div className="card-content-wrap">
                                        <div className="card-content">
                                            <a href={item.titleUrl}>
                                                <h5 className="card-meta">
                                                    <span>{item.cardTypeIcon}</span> {item.cardType}
                                                </h5>
                                                <h4 className="card-title">{item.title}
                                                    <i>{item.titleIcon}</i>
                                                </h4>
                                                <p className="card-sub">
                                                    {item.stitle}
                                                </p>
                                            </a>
                                            {/* <a href={item.authorUrl} className="author-img">
                                                <img src={item.author} alt="author-img" />
                                            </a> */}
                                            <ul className="info-list padding-top-20px">
                                                {item.glutenFree ? <li><span className="la d-inline-block"><SiGroupon /></span> Gluten/Wheat Friendly
                                                </li> : ''}
                                                {item.plantBased ? <li><span className="la d-inline-block"><RiPlantLine /></span> Plant Based
                                                </li> : ''}
                                                
                                                <li>
                                                    <span className="la d-inline-block"><FaRegCalendarCheck /></span> {item.date}
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="rating-row">
                                            <div className="rating-rating">
                                                {item.ratings.map((rating, index) => {
                                                    return (
                                                        <span key={index}>{rating}</span>
                                                    )
                                                })}
                                                <span className="rating-count">{item.ratingNum}</span>
                                            </div>
                                            <div className="listing-info">
                                                <ul>
                                                    <li><span className="info__count"><AiOutlineEye /></span> {item.view}</li>
                                                    <li>
                                                        <span className="info__save" data-toggle="tooltip" data-placement="top" title="Bookmark">
                                                            <FiHeart />
                                                        </span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </Slider>
                </div>
            </div>
        );
    }
}

export default PlaceOne;