import React, { useState } from 'react'
import { FiPlusCircle, FiBookmark } from 'react-icons/fi';
import { AiOutlineUser } from 'react-icons/ai';
import { BiFoodMenu } from "react-icons/bi";
import {Link} from "react-router-dom";
import Button from "../../common/Button";
import LogOutButton from "../../common/LogOutButton";
export default function HeaderAuthorAccess(props) {
    const [AuthorAccessOpen, setAuthorAccessOpen] = useState(false);
    console.log("HeaderAuthorAccess Props",props);
    return (
        <>
            <div className="logo-right-content">
                <ul className="author-access-list">
                    <li>
                        {/* <Link to="/login">login</Link>
                        <span className="or-text">or</span>
                        <Link to="/sign-up">Sign up</Link> */}
                        <LogOutButton />
                    </li>
                    <li>
                        {/* url="/add-listing/new"  */}
                        <Button text="add review" url="" >
                            <FiPlusCircle />
                        </Button>
                    </li>
                    <li>
                        {/* url="/add-listing/new"  */}
                        <Button text="Make a Reservation" url="" >
                            <FiPlusCircle />
                        </Button>
                    </li>
                </ul>
                <div className="side-user-menu-open" onClick={() => setAuthorAccessOpen(!AuthorAccessOpen)}>
                    <AiOutlineUser />
                </div>
            </div>

            {/* Side User panel */}
            <div className={AuthorAccessOpen ? 'side-user-panel active' : 'side-user-panel'}>
                <div className="humburger-menu">
                    <div className="humburger-menu-lines side-menu-close" onClick={() => setAuthorAccessOpen(!AuthorAccessOpen)}></div>
                </div>
                <div className="side-menu-wrap side-user-menu-wrap">

                    <div className="side-user-img">
                        <img src={props.user.picture} alt="User" />
                        <h4 className="su__name">{ props.user.name }</h4>
                        <span className="su__meta">Plan Level {props.user.subscription_level}</span>
                    </div>

                    <ul className="side-menu-ul">
                        <li><Link to="/dashboard"><AiOutlineUser className="user-icon" /> My Profile</Link></li>
                        {/* <li><Link to="/dashboard"><BsListCheck className="user-icon" /> My Listings</Link></li> */}
                        <li><Link to="/dashboard"><FiBookmark className="user-icon" /> My Favorites</Link></li>
                        {/* <li><Link to="/dashboard"><FiPlusCircle className="user-icon" /> add listing</Link></li> */}
                        <li><Link to="/dashboard"><FiPlusCircle className="user-icon" /> Make Reservation</Link></li>
                        <li><Link to="/pricing"><BiFoodMenu className="user-icon" /> Plans</Link></li>
                        <li><div className="dropdown-divider"></div></li>
                        {/* <li><Link to="#"><BsQuestion className="user-icon" /> help</Link></li> */}
                        {/* <li><Link to="#"><BsGear className="user-icon" /> Settings</Link></li> */}
                        {/* <li><Button onClick={() => logout({ returnTo: window.location.origin })}><BsPower className="user-icon" /> Sign Out</Button></li> */}
                    </ul>
                    {/* <div className="side-user-search contact-form-action">
                        <form method="post">
                            <div className="form-group mb-0">
                                <FiSearch className="form-icon" />
                                <input className="form-control" type="search" name="search-field" placeholder="Search by keywords" />
                            </div>
                            <button type="button" className="theme-btn border-0">
                                Search
                            </button>
                        </form>
                    </div> */}
                </div>
            </div>
        </>
    )
}
