import React from "react";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import HeaderAuthorAccess from "./HeaderAuthorAccess";
import LoginButton from "../../common/LoginButton";
import ClipLoader from "react-spinners/ClipLoader";
import axios from 'axios';
const CheckProfile = () => {
    console.log('Checking Profile')
    const { isAuthenticated, user } = useAuth0();
    if (isAuthenticated) {
        console.log('Is Authenticated');
        axios.get('https://lumiere-server-2q7e4.ondigitalocean.app/api/user_data', { params: { auth0_user_id: user.sub } })
            .then(res => {
                axios.get('https://lumiere-server-2q7e4.ondigitalocean.app/api/product_info', { params: { product_id: res.data.subscription_level } }).then((result) => {
                    user.subscription_level = result.data.name;
                }).catch((e) => {
                    console.log(e)
                })
            });
        console.log(user);
        return <HeaderAuthorAccess user={user} />;
    } else {
        console.log('Not Authenticated');
        return (
            <div className="logo-right-content">
                <ul className="author-access-list">
                    <li><LoginButton /></li>
                </ul>
            </div>
        ) 
    }
};

export default withAuthenticationRequired(CheckProfile, {
    onRedirecting: () => <ClipLoader />,
});