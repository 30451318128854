import React, { useState, useEffect} from 'react';
import SectionDivider from "../../common/SectionDivider";
import { FiCheck } from 'react-icons/fi'
import { loadStripe } from '@stripe/stripe-js';
import { useAuth0 } from "@auth0/auth0-react";
import axios from 'axios';
const stripePromise = loadStripe('pk_test_9CRQa6ujaFHRTBtcxyJvdN9X00kDvFiMJK');


const Plans = () => {
    const { user } = useAuth0();
    const [lists, setLists] = useState([]);
    const [isLoading, setLoading] = useState(true);
    
    useEffect(() => {
        if (user && isLoading) {
            let url = 'https://lumiere-server-2q7e4.ondigitalocean.app/api/stripe_catalog';
            axios.get(url)
                .then(res => {
                    if (res.data) {

                        const entries = res.data
                        let ct = 0;
                        entries.forEach(element => {
                            axios.get('https://lumiere-server-2q7e4.ondigitalocean.app/api/product_info', { params: { product_id: element.product } }).then(res => {
                                var price = element.unit_amount / 100;
                                var order = element.unit_amount;
                                var currency = '$';
                                var active = false;
                                var buttonTxt = 'Subscribe';
                                if (user.subscription_level === res.data.name) {
                                    active = true;
                                    buttonTxt = 'Cancel';
                                }
                                if (price === 0) {
                                    price = "Free";
                                    currency = '';
                                    order = 1;
                                }
                                var features = []
                                for (var key in res.metadata) {
                                    features.push({
                                        icon: <FiCheck />,
                                        iconClr: 'text-success',
                                        title: key
                                    })
                                }
                                
                                var plan = {
                                    // icon: <IoMdPaperPlane />,
                                    title: res.data.name,
                                    price: price,
                                    stripeId: element.id,
                                    stripePrice: element.unit_amount,
                                    currency: currency,
                                    features: features,
                                    buttonTxt: buttonTxt,
                                    buttonUrl: '#',
                                    active: active,
                                    order: order

                                }
                                setLists(plans => [...plans, plan]);
                                ct++;
                                if (ct >= entries.length) {
                                    setLoading(false);
                                }
                            })
                        });
                        
                    }
                })
                .catch(err => console.log(err))
        }
    },[user, isLoading])
    
    useEffect(() => {
        if (user) {
            axios.get('https://lumiere-server-2q7e4.ondigitalocean.app/api/user_data', { params: { auth0_user_id: user.sub } })
                .then(res => {
                    user.subscription_level = res.data.subscription_level
                });
        }
    },[user])
    const handleClick = async (event) => {
        // When the customer clicks on the button, redirect them to Checkout.
        //TODO: Grab user information and then update it with the new subscription info
        const stripe = await stripePromise;
        const { error } = await stripe.redirectToCheckout({
            lineItems: [
                // Replace with the ID of your price
                { price: event.target.id, quantity: 1 }
            ],
            mode: 'subscription',
            successUrl: process.env.REACT_APP_BASE_URL,
            cancelUrl: 'https://disneyfood.app',
            customerEmail: user.email
        });
        if (error) {
            console.log(error);
        }
        // If `redirectToCheckout` fails due to a browser or network
        // error, display the localized error message to your customer
        // using `error.message`.
    };
    if (isLoading) {
        return <div className="App">Loading...</div>;
    } else {
        return (
            <>
                <div className="row">
                    {lists.map((item, i) => {
                        return (
                            <div className="col-lg-3" key={i}>
                                <div className={item.active ? 'price-item pricing-active' : 'price-item'}>
                                    <div className="price-head">
                                        <i className="fa price__icon">
                                            {item.icon}
                                        </i>
                                        <h3 className="price__title mt-3">
                                            {item.title}
                                        </h3>
                                    </div>
                                    <div className="price-content">
                                        <div className="price-number">
                                            <p className="price__value">
                                                <sup>{item.currency}</sup>{item.price}
                                            </p>
                                            <p className="price__subtitle">{item.mo}</p>
                                        </div>

                                        <SectionDivider />

                                        <div className="price-list-item">

                                            <ul className="list-items text-left">
                                                {item.features.map((ftitem, index) => {
                                                    return (
                                                        <li key={index}>
                                                            <i className={'la ' + ftitem.iconClr}>{ftitem.icon}</i> {ftitem.title}
                                                        </li>
                                                    )
                                                })}
                                            </ul>

                                            <div className="btn-box text-center margin-top-35px">
                                                {/* <SignUpSwitch item={item} /> */}
                                                <button class="btn-box text-center margin-top-35px" role="link" id={item.stripeId} onClick={handleClick}>
                                                    {item.buttonTxt}
                                                </button>
                                            </div>

                                        </div>
                                    </div>

                                    {item.active ? (
                                        <div className="recommended-wrap padding-bottom-30px">
                                            <i className="la"><FiCheck /></i>
                                            <span>Current Plan</span>
                                        </div>
                                    ) : ''}

                                </div>
                            </div>
                        )
                    })}
                </div>
            </>
        );
    }
}

export default Plans;
