import React, { Component} from 'react';
import Select from "react-select";
import axios from 'axios';
class SelectPark extends Component {
    constructor(props) { 
        super(props);
        this.state = {
            parks:[],
        }
    }

    // state = {
    //     parks: [],
    //     selectedPark:null
    // }
    componentDidMount() {
        this.getParks();
    }
    getParks = () => {
        axios.get('https://lumiere-server-2q7e4.ondigitalocean.app/api/parks')
            .then(res => {
                if (res.data) {
                    this.setState({
                        parks: res.data
                    })
                }
            })
            .catch(err => console.log(err))
    }
    render() {
        let { parks } = this.state;
        return (

            <>
                    <Select
                        // value={this.selectedPark}
                            placeholder="Select a Park, Area or Resort"
                            options={parks}
                            getOptionLabel={(option) => option.name}
                            getOptionValue={(option) => option.id}
                        // onChange={this.handleChange}
                    />
            </>
        );
    }
}

export default SelectPark;