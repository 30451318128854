import React, { useState, useEffect } from 'react'
import  { FiSearch } from 'react-icons/fi'
import Select from 'react-select';
import { Formik, Form } from 'formik';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
const BannerOneSearchInput = (props) => { 
    
    const [search, setSearch] = useState('');
    const [selectedPark, setSelectedPark] = useState(0);
    const [selectedCategory, setSelectedCategory] = useState(0);
    const [parks, setParks] = useState([]);
    const [categories, setCategories] = useState([]);
    const [redirect, setRedirect] = useState(false);
    const [loaded, setLoaded] = useState(false)
    useEffect(() => {
        axios.get('https://lumiere-server-2q7e4.ondigitalocean.app/api/parks')
            .then(res => setParks(res.data)).then(
                axios.get('https://lumiere-server-2q7e4.ondigitalocean.app/api/categories').then(res => setCategories(res.data))
        )
        return () => (setLoaded(true))
    }, [loaded])
    const submit = () => {
        // setPark(park);
        setRedirect(true);

    };
    
    const searchBarChange = (e) => { 
        setSearch(e.target.value);
    }

    const selectParkChange = (e) => { 
        setSelectedPark(e.id);
    }

    const selectCatChange = (e) => {
        setSelectedCategory(e.id);
    }
    if (redirect) { 
        return <Redirect push to={`/search-results/${search}/${selectedPark}/${selectedCategory}`}/>
    } 
    return (
        <Formik
            onChange={() => {
                console.log('changing');
            }}
            onSubmit={values => submit(search, selectedPark, selectedCategory)}
            initialValues={{ park:0, search: '',selectedPark:0 }}
        >{({ handleChange }) => (
            <Form>
                <div className="main-search-input">
                    <div className="main-search-input-item">
                        <div className="contact-form-action">

                            <div className="form-group mb-0">
                                <span className="form-icon">
                                    <FiSearch />
                                </span>
                                <input className="form-control" type="text" id="searchBar" name="bar" value={search} onChange={searchBarChange}
                                    placeholder='What are you looking for?' />
                            </div>
                        </div>
                    </div>
                    <div className="main-search-input-item location">
                        <Select
                            // value={selectedPark}
                            placeholder="Select a Park, Area or Resort"
                            options={parks}
                            getOptionLabel={(option) => option.name}
                            getOptionValue={(option) => option.id}
                            onChange={selectParkChange}
                        />
                    </div>

                    <div className="main-search-input-item category">
                            <Select
                                onChange={selectCatChange}
                                placeholder="Select a Category"
                                options={categories}
                                getOptionLabel={(option) => option.name}
                                getOptionValue={(option) => option.id}
                            />
                    </div>

                    <div className="main-search-input-btn">
                        <button className="button theme-btn" type="submit">Search</button>
                    </div>

                </div>
            </Form>
        )}
        </Formik>
    );
}
export default BannerOneSearchInput;