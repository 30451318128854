import React, {Component} from 'react';
import { GiCakeSlice } from 'react-icons/gi'
import { AiOutlineUser } from 'react-icons/ai'
import { BsPeople } from 'react-icons/bs'

class InfoBoxOne extends Component {
    states = {
        items: [
            {
                id: '1',
                icon: <AiOutlineUser />,
                title: 'Create a Profile',
                description: ''
            },
            {
                id: '2',
                icon: <GiCakeSlice />,
                title: 'Write Reviews',
                description: ''
            },
            {
                id: '3',
                icon: <BsPeople />,
                title: 'Gain Followers',
                description: ''
            }
        ]
    }
    render() {
        return (
            <>
                {this.states.items.map(item => {
                    return (
                        <div className="col-lg-4 column-td-6" key={item.id}>
                            <div className="icon-box">
                                <div className="info-icon">
                                    {item.icon}
                                    <span className="info-number">{item.id}</span>
                                </div>
                                <div className="info-content">
                                    <h4 className="info__title">
                                        {item.title}
                                    </h4>
                                    <p className="info__desc">
                                        {item.description}
                                    </p>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </>
        );
    }
}

export default InfoBoxOne;