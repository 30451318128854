import React, {Component} from 'react';
import { IoIosCheckmarkCircle, IoIosLink, IoMdStar, IoMdStarHalf, IoMdStarOutline} from "react-icons/io";
import {GiChickenOven} from "react-icons/gi";
import {RiHotelBedLine, RiPlaneLine} from "react-icons/ri";
import {FiHeart} from "react-icons/fi";
import {Link} from "react-router-dom";
import authorimg from '../../assets/images/small-team1.jpg';

class PlaceGrid extends Component {
    state = {
        sample: [
            {
                badge: 'New Open',
                title: 'Favorite Place Food Bank',
                titleIcon: <IoIosCheckmarkCircle />,
                titleUrl: '/listing-details',
                stitle: "this.props.results[0].locations[0].name",
                image: '',
                cardType: 'Restaurant',
                cardTypeIcon: <GiChickenOven />,
                author: authorimg,
                authorUrl: '#',
                number: '(492) 492-4828',
                website: 'www.mysitelink.com',
                date: 'Posted 1 month ago',
                view: '204',
                ratings: [
                    <IoMdStar />,
                    <IoMdStar />,
                    <IoMdStar />,
                    <IoMdStarHalf />,
                    <IoMdStar className="last-star" />,
                ],
                ratingNum: '4.5'
            },
            {
                badge: 'Closed',
                title: 'beach blue boardwalk',
                titleIcon: '',
                titleUrl: '/listing-details',
                stitle: 'Bishop Avenue, New York',
                image: 'img2',
                cardType: 'Travel',
                cardTypeIcon: <RiPlaneLine />,
                author: authorimg,
                authorUrl: '#',
                number: '(492) 492-4828',
                website: 'www.mysitelink.com',
                date: 'Posted 1 month ago',
                view: '248',
                ratings: [
                    <IoMdStar />,
                    <IoMdStar />,
                    <IoMdStar />,
                    <IoMdStarHalf />,
                    <IoMdStar className="last-star" />,
                ],
                ratingNum: '4.6'
            },
            {
                badge: 'New Open',
                title: 'Hotel Govendor',
                titleIcon: <IoIosCheckmarkCircle />,
                titleUrl: '/listing-details',
                stitle: 'Bishop Avenue, New York',
                image: 'img3',
                cardType: 'Hotel',
                cardTypeIcon: <RiHotelBedLine />,
                author: authorimg,
                authorUrl: '#',
                number: '(492) 492-4828',
                website: 'www.mysitelink.com',
                date: 'Posted 1 month ago',
                view: '248',
                ratings: [
                    <IoMdStar />,
                    <IoMdStar />,
                    <IoMdStar />,
                    <IoMdStarHalf />,
                    <IoMdStar className="last-star" />,
                ],
                ratingNum: '4.6'
            },
            {
                badge: 'New Open',
                title: 'Favorite Place Food Bank',
                titleIcon: <IoIosCheckmarkCircle />,
                titleUrl: '/listing-details',
                stitle: 'Bishop Avenue, New York',
                image: 'img4',
                cardType: 'Restaurant',
                cardTypeIcon: <GiChickenOven />,
                author: authorimg,
                authorUrl: '#',
                number: '(492) 492-4828',
                website: 'www.mysitelink.com',
                date: 'Posted 1 month ago',
                view: '204',
                ratings: [
                    <IoMdStar />,
                    <IoMdStar />,
                    <IoMdStar />,
                    <IoMdStarHalf />,
                    <IoMdStar className="last-star" />,
                ],
                ratingNum: '4.5'
            },
            {
                badge: 'Closed',
                title: 'beach blue boardwalk',
                titleIcon: '',
                titleUrl: '/listing-details',
                stitle: 'Bishop Avenue, New York',
                image: 'img5',
                cardType: 'Travel',
                cardTypeIcon: <RiPlaneLine />,
                author: authorimg,
                authorUrl: '#',
                number: '(492) 492-4828',
                website: 'www.mysitelink.com',
                date: 'Posted 1 month ago',
                view: '248',
                ratings: [
                    <IoMdStar />,
                    <IoMdStar />,
                    <IoMdStar />,
                    <IoMdStarHalf />,
                    <IoMdStar className="last-star" />,
                ],
                ratingNum: '4.6'
            },
            {
                badge: 'New Open',
                title: 'Hotel Govendor',
                titleIcon: <IoIosCheckmarkCircle />,
                titleUrl: '/listing-details',
                stitle: 'Bishop Avenue, New York',
                image: 'img6',
                cardType: 'Hotel',
                cardTypeIcon: <RiHotelBedLine />,
                author: authorimg,
                authorUrl: '#',
                number: '(492) 492-4828',
                website: 'www.mysitelink.com',
                date: 'Posted 1 month ago',
                view: '248',
                ratings: [
                    <IoMdStar />,
                    <IoMdStar />,
                    <IoMdStar />,
                    <IoMdStarHalf />,
                    <IoMdStar className="last-star" />,
                ],
                ratingNum: '4.6'
            },
        ],
        items: [],
        results: []
    };
    constructor(props) { 
        super(props)
        var arr = [];
        Object.keys(props.results).forEach(function (key) {
            arr.push(props.results[key]);
        });
        this.state.results = arr;
        console.log(this.state.results)
    }
    ratingsToStars(ratings) { 

        let stars = [];
        let rating = 0;
        let i;
        for (i = 0; i < ratings.length; i++) { 
            console.log(ratings[i].rating)
            rating = rating + ratings[i].rating;
        }
        rating = rating / ratings.length;
        for (i = 0; i < 5; i++) { 
            if (i + 1 < rating) {
                stars.push(< IoMdStar />)
            } else if (rating - i < 1 && rating - i > .01) {
                stars.push(< IoMdStarHalf className="last-star"/>)
            } else if (rating -i  < 0) { 
                stars.push(<IoMdStarOutline className="last-star"/>)
            }
        }
        return stars;
    }
    ratingsAverage(ratings) { 
        let rating = 0;
        let i;
        for (i = 0; i < ratings.length; i++) {
            console.log(ratings[i].rating)
            rating = rating + ratings[i].rating;
        }
        rating = rating / ratings.length;
        return rating;
    }

    allRatings(item) { 
        return (
            <div className="rating-rating">
                {/* {item.ratings.map((rating, index) => {
                                                return (
                                                    <span key={index}>{rating}</span>
                                                )
                                            })} */}
                {this.ratingsToStars(item.ratings)}
                <span className="rating-count">{this.ratingsAverage(item.ratings)}</span>
            </div>
        )
    }
    userRating() { 
        return (
                <div className="rating-rating">
                    <IoMdStarOutline />
                    <IoMdStarOutline />
                    <IoMdStarOutline />
                    <IoMdStarOutline />
                    <IoMdStarOutline className="last-star" />
                    <span className="rating-count">0.0</span>
                </div>
            )
    }
    
    render() {
        
        return (
            <>
                
                {
                    this.state.results.map((item, index) => {
                    return (
                        <div className="col-lg-4 column-td-6" key={index}>
                            <div className="card-item">
                                <Link to={item.titleUrl} className="card-image-wrap">
                                    <div className="card-image">
                                        {/* <img src={item.image} className="card__img" alt="Place" />
                                        <span className={item.titleIcon ? 'badge': 'badge badge-closed' }>{item.badge}</span> */}
                                        {/* <span className="badge-toggle" data-toggle="tooltip" data-placement="bottom" title="22 Likes">
                                            <FiHeart />
                                        </span> */}
                                    </div>
                                </Link>
                                <div className="card-content-wrap">
                                    <div className="card-content">
                                        <Link to={item.titleUrl}>
                                            {/* <h5 className="card-meta">
                                                <span>{item.cardTypeIcon}</span> {item.cardType}
                                            </h5> */}

                                            <h4 className="card-title">{item.name }
                                                
                                            </h4>
                                            <p className="card-sub">
                                                {item.description}
                                            </p>
                                            <p className="card-sub">
                                                {'$'+item.price}
                                            </p>
                                        </Link>
                                        {/* <a href={item.authorUrl} className="author-img">
                                            <img src={item.author} alt="author-img" />
                                        </a> */}
                                        <ul className="info-list padding-top-20px">
                                            <li><span className="la d-inline-block"><IoIosLink /></span>  <a href={this.props.results[0].location.locationUrl}>
                                                {item.location.name + " Menu"}
                                            </a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="rating-row"
                                        onMouseEnter={() => this.userRating(item)}
                                        onMouseLeave={() => this.allRatings(item)}>
                                        <span></span>
                                        <div className="listing-info">
                                            <ul>
                                                <li>
                                                    <span className="info__save" data-toggle="tooltip" data-placement="top" title="Bookmark">
                                                        <FiHeart />
                                                    </span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </>
        );
    }
}

export default PlaceGrid;